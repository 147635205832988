import { KitemType } from "../contexts/LearningContext";
import { ImageChooserKitem } from "../controller/ImageChooserController";


export const ErsteWorteKitems: (ImageChooserKitem)[] = [
	{
		id: "ersteworte_mama",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "mama",
		// variant: "first-words",
	},
	{
		id: "ersteworte_papa",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "papa",
		// variant: "first-words",
	},
	{
		id: "ersteworte_janosch",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "janosch",
		// variant: "first-words",
	},
	{
		id: "ersteworte_hund",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "hund",
		// variant: "first-words",
	},
	{
		id: "ersteworte_katze",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "katze",
		// variant: "first-words",
	},
	{
		id: "ersteworte_schaf",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schaf",
		// variant: "first-words",
	},
	{
		id: "ersteworte_pferd",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "pferd",
		// variant: "first-words",
	},
	{
		id: "ersteworte_maus",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "maus",
		// variant: "first-words",
	},
	{
		id: "ersteworte_ente",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "ente",
	},
	{
		id: "ersteworte_schwein",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schwein",
	},
	{
		id: "ersteworte_affe",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "affe",
	},
	{
		id: "ersteworte_kuh",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "kuh",
	},
	{
		id: "ersteworte_igel",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "igel",
	},
	{
		id: "ersteworte_loewe",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "loewe",
	},
	{
		id: "ersteworte_fuchs",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "fuchs",
	},
	{
		id: "ersteworte_taube",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "taube",
	},
	{
		id: "ersteworte_pinguin",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "pinguin",
	},
	{
		id: "ersteworte_frosch",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "frosch",
	},
	{
		id: "ersteworte_eule",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "eule",
	},
	{
		id: "ersteworte_biene",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "biene",
	},
	{
		id: "ersteworte_huhn",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "huhn",
	},

];